import * as React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Modal, Typography } from 'antd';

import { ApplicationState } from '../../store';
import * as PageStore from '../../store/Page';
import * as TasksStore from '../../store/Tasks';
import * as UserProfileStore from '../../store/UserProfile';
import * as SettingsStore from '../../store/SettingsStore';
import * as AdminStore from '../../store/AdminStore';
import { BaseProfile, Group, Point, Resource, SUserProfileDTO, USER_RIGHTS, SUserDTO, Command, MoveOperationsEnum } from '../../decl';
import * as Const from '../../utils/Const';
import { getModalZIndex, message } from '../../utils/Common';

import PointsControlTree, { NodeTypesEnum } from '../PointsControlTree';
import SelectForm from './SelectForm';
import { GroupForm, GroupFormProps, GroupValues } from './GroupForm';
import PointEditForm from './PointEditForm'
import QueryItemsForm from './QueryItemsForm';

import { sendRequestToBackend } from '../../utils/AuthUtils';
import UsersTable from './UsersTable';
import { ArrayDestructuringAssignment } from 'typescript';
import { userInfo } from 'os';
import PollingForm from './PollingForm';
import { getAllSelectedPoints } from '../../utils/TreeUtils';
import { PointAllInfoDTO } from '../../store/AdminStore';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...AdminStore.actionCreators, ...TasksStore.actionCreators };


type UsersManagerProps =
    {
        page: PageStore.PageState,
        tasks: TasksStore.TasksState,
        settings: SettingsStore.ISettingsState,
        profile: BaseProfile,
        userProfile: UserProfileStore.UserProfileState,
        pointId: string,
        adminStore: AdminStore.IAdminState,
        isMenuCollapsed: boolean,
        windowWidth: number,
        windowHeight: number,
    } &
    typeof actionCreators &
    {
        selectedUser: SUserDTO | undefined,
        onSelectUser: (user: SUserDTO | undefined)=>void
    }

type SelectObjectsModalType = {
    visible: boolean,
    zIndex: number,
    group: Group | null,
    onlyGroupsSelect: boolean,
    selectedPointsIds: string[],
    selectedGroupsIds: string[],
}

interface IUsersManagerState {
    resources: Resource[],
    userGroups: Group[] | undefined,
    groupPoints: { [groupId: string]: Point[] },
    allPoints: { [pointId: string]: Point },

    currentPointId: string | undefined,

    showPointEditForm: boolean,
    modalZIndex: number,
    pointGroup: Group | null,

    groupFormIsOpen: boolean,
    isNewGroup: boolean,
    groupValues: GroupValues | undefined,
    group: Group | null,

    selectObjectsModal: SelectObjectsModalType
    showQueryItemsFormPoint: Point | null,

    pollingAbonents: Point[] | null,
    pollingGroupName: string | null;
}

class UsersManager extends React.PureComponent<UsersManagerProps, IUsersManagerState> {

    constructor(props: UsersManagerProps) {
        super(props);

        this.state = {
            resources: [],
            userGroups: undefined,
            groupPoints: {},
            allPoints: {},

            currentPointId: undefined,

            showPointEditForm: false,
            modalZIndex: 0,
            pointGroup: null,

            groupFormIsOpen: false,
            isNewGroup: false,
            groupValues: undefined,
            group: null,

            selectObjectsModal: { visible: false, zIndex: 0, group: null, onlyGroupsSelect: false, selectedPointsIds: [], selectedGroupsIds: [] },
            showQueryItemsFormPoint: null,

            pollingAbonents: null,
            pollingGroupName: null,
        }
    }
    componentDidMount() {
        //this.props.setIsLoading(true);
        if (this.props.userProfile.user?.userKey) {
            this.loadUserProfile(this.props.userProfile.user?.userKey, this.props.page.pointId);
        }
    }

    componentDidUpdate(prevProps: UsersManagerProps, prevState: IUsersManagerState) {
        if (this.props.selectedUser && this.props.selectedUser.userKey && prevProps.selectedUser?.userKey !== this.props.selectedUser?.userKey) {
            this.loadUserProfile(this.props.selectedUser.userKey, null);
        }
        if (prevProps.adminStore.isPointWriteComplete === false && this.props.adminStore.isPointWriteComplete === true && this.props.adminStore.pointAllInfo !== undefined) {
            this.updateTree(this.props.adminStore.pointAllInfo.pointInfo.id, this.props.adminStore.pointAllInfo.pointInfo.number);
        }
    }

    // selectPoint = (pointId: string) => {
    //     if (this.props.userProfile) {
    //         this.props.changeProfile(Const.PROFILE_COMMON, {Points: pointId});
    //         const point: Point = this.props.userProfile.allPoints[pointId];
    //         this.props.setPoint(point.id, point.number);
    //     }
    // };

    loadUserProfile = (userKey: string | undefined, pointId: string | null) => {
        //console.log('loadUserProfile', userKey);
        this.setState({ userGroups: undefined });

        sendRequestToBackend(
            { reset: false, userKey: userKey },
            'profile/getall',
            (userProfile: SUserProfileDTO) => {
                //console.log('LOAD', userProfile.groupPoints);
                this.onSelectUser(userProfile.user);
                this.setState({
                    resources: userProfile.resources,
                    userGroups: userProfile.userGroups,
                    groupPoints: userProfile.groupPoints,
                    allPoints: userProfile.allPoints,
                    currentPointId: pointId ? pointId : undefined
                });
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Не удалось загрузить профиль выбранного пользователя.");
                console.log(error);
            }
        );
    }

    updateTree = (pointId: string | null, pointNumber: string) => {
        if (this.props.selectedUser?.userKey) {
            this.loadUserProfile(this.props.selectedUser.userKey, pointId)
        }
    }

    requestGroupPoints = (iGroupId: string, resolve: any, reset: Boolean | undefined) => {
        //console.log('requestGroiupPoints', iGroupId);
        let properties = Object.getOwnPropertyNames(this.state.groupPoints);
        if (reset || properties.indexOf(iGroupId) < 0) {
            sendRequestToBackend(
                iGroupId,
                'points/grouppoints',
                (points: Point[]) => {
                    let newGroupPoints: { [groupId: string]: Point[] } = { ...this.state.groupPoints };
                    newGroupPoints[iGroupId] = points;

                    let newAllPoints: { [groupId: string]: Point } = { ...this.state.allPoints };
                    for (let point of points) {
                        newAllPoints[point.id] = point;
                    }
                    this.setState({ groupPoints: newGroupPoints, allPoints: newAllPoints });
                    resolve(points);
                    //console.log('rreceiveGroiupPoints', iGroupId, points);
                },
                this.props.setIsLoading);
        }
        else {
            resolve(this.state.groupPoints[iGroupId]);
        }
    }

    onSelect = (ob: Point | Group | null, nodeType: NodeTypesEnum) => {
    };

    createGroup = (parent: Group | null) => {
        this.setState({ group: parent, groupFormIsOpen: true, isNewGroup: true, groupValues: { title: '', description: '' } });
    }

    editGroup = (group: Group) => {
        this.setState({ group: group, groupFormIsOpen: true, isNewGroup: false, groupValues: { title: group.name, description: '' } });
    }

    removeGroup = (group: Group, parent: Group) => {
        Modal.confirm({
            title: parent ? 'Удаление группы из группы' : 'Удаление корневой группы',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: <div>Вы собираетесь удалить группу '{group.name}' {parent ? " из группы '" + parent.name + "'" : " из корневых групп пользователя"}.
                Группа останется в системе, но будет <span style={{ color: 'red' }}>НЕ ДОСТУПНА</span> здесь.
                Если она нигде больше не представлена, то пользователь не будет иметь доступа к точкам учёта из неё.</div>,
            width: 550,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                const fromGroup = parent;
                this.moveGroup(group, parent, undefined, MoveOperationsEnum.Remove);
                // if(fromGroup)
                //     this.requestGroupPoints(
                //         fromGroup.id, ()=>{
                //     }, true);
            },
            onCancel: () => {
            }
        });
    }

    deleteGroup = (group: Group) => {
        Modal.confirm({
            title: 'Удаление группы из системы',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: <div style={{ color: 'red' }}>Вы собираетесь удалить группу '{group.name}'.
                Точки учёта из этой группы останутся в системе, но будут доступны только из других групп.
                Вы уверены, что хотите УДАЛИТЬ группу?</div>,
            width: 400,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                this.deleteGroupAccept(group);
            },
            onCancel: () => {
            }
        });
    }

    removePoint = (point: Point, group: Group) => {
        Modal.confirm({
            title: 'Удаление точки учёта из группы',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: <div>Вы собираетесь убрать точку учёта '{point.number}' из группы '{group.name}'. Точка учёта останется в системе, но будет не доступна для этой группы.</div>,
            width: 400,
            okText: 'Убрать',
            cancelText: 'Отмена',
            onOk: () => {
                const fromGroup = group;
                this.movePoint(point, group, undefined, MoveOperationsEnum.Remove);
                if (fromGroup) this.requestGroupPoints(fromGroup.id, () => {
                }, true);
            },
            onCancel: () => {
            }
        });
    }

    addGroups = (group: Group | null, parent: Group | null) => {
        this.setState({
            selectObjectsModal: {
                visible: true,
                zIndex: getModalZIndex(),
                group: group,
                onlyGroupsSelect: true,
                selectedGroupsIds: (group === null ? (this.state.userGroups ? this.state.userGroups.map(g => g.id) : []) : []),
                selectedPointsIds: []
            }
        });
    }

    addPoints = (group: Group) => {
        this.setState({ selectObjectsModal: { visible: true, zIndex: getModalZIndex(), group: group, onlyGroupsSelect: false, selectedGroupsIds: [], selectedPointsIds: [] } });
    }

    changePointPosition = (group: Group, point: Point, afterPoint: Point | undefined) => {
        let lowerId;
        if (afterPoint == null) {
            const points: Point[] = this.state.groupPoints[group.id];
            if (points.length > 0) {
                lowerId = points[0].id;
            }
        }

        sendRequestToBackend(
            { userId: this.props.selectedUser?.userKey, parentId: group.id, id: point.id, upperId: afterPoint?.id, lowerId: lowerId },
            'points/changeposition',
            (points: Point[]) => {
                let newGroupPoints: { [groupId: string]: Point[] } = { ...this.state.groupPoints };
                newGroupPoints[group.id] = points;

                this.setState({ groupPoints: newGroupPoints });
                //                this.loadUserProfile(this.state.userKey, point.id);
            },
            this.props.setIsLoading,
            () => {

            },
            () => {

            },
            500
        );
    }

    changeGroupPosition = (parent: Group, group: Group, afterGroup: Group | undefined) => {
        let lowerId;
        if (afterGroup == null) {
            if (parent.childGroups.length > 0) {
                lowerId = parent.childGroups[0].id;
            }
        }

        sendRequestToBackend(
            { userId: this.props.selectedUser?.userKey, parentId: parent?.id, id: group.id, upperId: afterGroup?.id, lowerId: lowerId },
            'points/changegroupposition',
            () => {
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            () => {

            },
            () => {

            },
            500
        );
    }

    createGroupAccept = (parent: Group | null, groupName: string, groupDescription: string) => {
        const userId = this.props.selectedUser?.userKey;
        sendRequestToBackend(
            { userId: userId, parentId: parent ? parent.id : null, groupName: groupName, groupDescription: groupDescription },
            'points/creategroup',
            () => {
                message.success('Группа создана!');
                this.loadUserProfile(userId, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {

            },
            500
        );
    }

    changeGroupAccept = (group: Group, groupName: string, groupDescription: string) => {
        sendRequestToBackend(
            { groupId: group.id, groupName: groupName, groupDescription: groupDescription },
            'points/changegroup',
            () => {
                message.success('Группа изменена!');
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {

            },
            500
        );
    }

    deleteGroupAccept = (group: Group) => {
        sendRequestToBackend(
            group.id,
            'points/deletegroup',
            () => {
                message.success('Группа удалена!');
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {
                message.error('Ошибка при удалении группы.');
            },
            500
        );
    }

    movePoint = (point: Point, fromGroup: Group | undefined, toGroup: Group | undefined, operation: MoveOperationsEnum) => {

        sendRequestToBackend(
            { id: point.id, fromId: fromGroup?.id, toId: toGroup?.id, operation: operation },
            'points/movepoint',
            () => {
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {
                message.error('Ошибка при перемещении точки учёта.');
            },
            500
        );
    }

    moveGroup = (group: Group, fromGroup: Group | undefined, toGroup: Group | undefined, operation: MoveOperationsEnum) => {
        sendRequestToBackend(
            { userId: this.props.selectedUser?.userKey, id: group.id, fromId: fromGroup?.id, toId: toGroup?.id, operation: operation },
            'points/movegroup',
            () => {
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {
                message.error('Ошибка при перемещении группы.');
            },
            500
        );
    }

    addGroupPoints = (group: Group, pointIds: string[]) => {
        sendRequestToBackend(
            { groupId: group.id, childIds: pointIds },
            'points/addpoints',
            () => {
                message.success('Точки учёта добавлены!');
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {
                message.error('Ошибка при добавлении точек учёта в группу.');
            },
            500
        );
    }

    addGroupGroups = (group: Group, groupIds: string[]) => {
        sendRequestToBackend(
            { groupId: group.id, childIds: groupIds },
            'points/addgroups',
            () => {
                message.success('Группы добавлены!');
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {
                message.error('Ошибка при добавлении групп в группу.');
            },
            500
        );
    }

    setUserGroups = (user: SUserDTO | undefined, groupIds: string[]) => {
        if (groupIds.includes(Const.ALL_GROUP_ID)) {
            groupIds = this.props.userProfile.userGroups.map(g => g.id);
        }

        sendRequestToBackend(
            { userId: user?.userKey, childIds: groupIds },
            'points/setusergroups',
            () => {
                message.success('Группы для пользователя установлены!');
                this.loadUserProfile(this.props.selectedUser?.userKey, null);
                this.props.requestAllUserProfile(false, () => { });
            },
            this.props.setIsLoading,
            (error: string) => {
                message.warning(error);
            },
            () => {
                message.error('Ошибка при добавлении групп в группу.');
            },
            500
        );
    }

    onGroupsSelect = (groups: Group[]) => {
        const groupIds: string[] = groups.map(g => g.id);
        const group = this.state.selectObjectsModal.group;
        if (group) {
            const addIds: string[] = [];
            const existGroups: Group[] = [];

            for (let addGroupId of groupIds) {
                const existGroup = group.childGroups.find(g => g.id === addGroupId);
                if (existGroup) {
                    existGroups.push(existGroup);
                }
                else {
                    addIds.push(addGroupId);
                }
            }
            Modal.confirm({
                title: 'Добавление групп в группу',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: <div>Вы собираетесь добавить группы в группу '{group.name}'.  <br />
                    Выбрано: {groupIds.length}. Из них уже есть: {existGroups.length}. Будет добавлено: {addIds.length}. </div>,
                width: 600,
                okText: 'Добавить',
                cancelText: 'Отмена',
                onOk: () => {
                    this.addGroupGroups(group, addIds);
                },
                onCancel: () => {
                }
            });
        }
        else {
            Modal.confirm({
                title: 'Установка групп для пользователя',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: <div>Вы собираетесь установить группы для пользователя '{this.props.selectedUser?.user}'. </div>,
                width: 600,
                okText: 'Установить',
                cancelText: 'Отмена',
                onOk: () => {
                    this.setUserGroups(this.props.selectedUser, groupIds);
                },
                onCancel: () => {
                }
            });

        }

        this.setState({ selectObjectsModal: { visible: false, zIndex: 0, group: null, onlyGroupsSelect: true, selectedGroupsIds: [], selectedPointsIds: [] } });
    };

    onSelectUser = (user: SUserDTO | null) => {
        this.props.onSelectUser(user ? user : this.props.userProfile.user);
    }

    //Добавление точек учёта в группу.
    onPointsSelect = (points: Point[]) => {
        const pointIds: string[] = points.map(p => p.id);
        if (this.state.selectObjectsModal.group != null) {
            const group: Group = this.state.selectObjectsModal.group;
            this.requestGroupPoints(
                group.id,
                (groupPoints: Point[]) => {
                    const addIds: string[] = [];
                    const existPoints: Point[] = [];

                    for (let addPointId of pointIds) {
                        const existPoint = groupPoints.find(p => p.id === addPointId);
                        if (existPoint) {
                            existPoints.push(existPoint);
                        }
                        else {
                            addIds.push(addPointId);
                        }
                    }

                    Modal.confirm({
                        title: 'Добавление точек учёта в группу',
                        wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                        zIndex: getModalZIndex(),
                        content: <div>Вы собираетесь добавить точки учёта в группу '{group.name}'.  <br />
                            Выбрано: {pointIds.length}. {existPoints.length == - 0 ? '' : 'Из них уже есть: ' + existPoints.length + '. Будет добавлено: ' + addIds.length + '.'} </div>,
                        width: 600,
                        okText: 'Добавить',
                        cancelText: 'Отмена',
                        onOk: () => {
                            this.addGroupPoints(group, addIds);
                        },
                        onCancel: () => {
                        }
                    });
                },
                false);

            this.setState({ selectObjectsModal: { visible: false, zIndex: 0, group: null, onlyGroupsSelect: false, selectedGroupsIds: [], selectedPointsIds: [] } });
        }

    };

    onPointWrite = (close: boolean, finish: (success: boolean, point: PointAllInfoDTO) => void) => {
        if (this.props.adminStore.pointAllInfo !== undefined) {
            if (this.props.adminStore.pointAllInfo.pointInfo.id === null) {
                this.props.requestPointCreate(this.props.adminStore.pointAllInfo, this.state.pointGroup?.id, false, this.props.adminStore.queryItem, (point: PointAllInfoDTO) => {
                    finish ? finish(true, point) : null;
                    if (close) {
                        this.setState({ showPointEditForm: false });
                    }
                });
            } else {
                this.props.requestPointWrite(this.props.adminStore.pointAllInfo, (s: boolean, point: PointAllInfoDTO) => {
                    finish ? finish(s, point) : null;
                    if (s === true && close) {
                        this.setState({ showPointEditForm: false });
                    }
                });
            }
        }
    }

    readPointSettings = (pointId: string | undefined | null, success?: () => void) => {
        if (pointId) {
            //this.props.setIsLoading(true, 200, "Сбор данных с прибора, это может занять несколько минут.");
            const point: Point = this.props.userProfile.allPoints[pointId];
            this.props.addPointLog(point);

            this.props.readData(
                0,
                [pointId],
                100,
                true,
                (code: number, ids: any) => {
                    if (code === 0) {
                        message.success('Запрос на получение данных из приборов отправлен.');
                    }
                    if (code === 2) {
                        //this.props.setIsLoading(false);
                        message.success('Данные из приборов получены.');
                        success ? success() : null;
                    }
                    if (code === 4) {
                        message.warning('Не все данные из приборов удалось получить.');
                        //this.props.setIsLoading(false);
                        success ? success() : null;
                    }
                },
                (error: string) => {
                    //this.props.setIsLoading(false);

                    message.error(error);
                });
        }
        else {
            message.error('Точка учёта для сбора настроек не определена');
        }
    }


    //Создание точки учёта.
    createPoint = (parent: Group | null) => {
        this.setState({ showPointEditForm: true, modalZIndex: getModalZIndex(), pointGroup: parent });
        this.props.requestPointDicts();
        this.props.requestQueryItemTypes();
        this.props.requestPointAllInfo(null, false);
    }

    //Редактирование точки учёта.
    editPoint = (point: Point) => {
        this.setState({ showPointEditForm: true, modalZIndex: getModalZIndex() });
        this.props.requestPointDicts();
        let id = point.id;
        this.props.requestPointAllInfo(id, false);
    }

    //Удаление точки учёта.
    deletePoint = (point: Point) => {
        Modal.confirm({
            title: 'Удаление точки учёта',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: <div style={{ color: 'red' }}>Вы собираетесь удалить точку учёта '{point.number}'.
                По этой команде точка учёта и все её архивы будут безвозвратно удалены из системы.
                Вы уверены, что хотите УДАЛИТЬ точку учёта?</div>,
            width: 400,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                sendRequestToBackend(
                    point.id,
                    'admin/pointdelete',
                    (response: any) => {
                        if (response != null && response === true) {
                            message.success("Точка учёта '" + point.number + "' удалена!");
                            this.updateTree(point.id, point.number);
                        } else {
                            message.warning("Точку учёта '" + point.number + "' удалить не удалось!");
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Точку учёта '" + point.number + "' удалить не удалось! Исключение при выполнении.");
                        console.log(error);
                        message.error(error);
                    }
                );
            },
            onCancel: () => {

            }
        });
    }

    //Копирование точки учёта.
    copyPoint = (point: Point, group: Group) => {
        this.setState({ showPointEditForm: true, modalZIndex: getModalZIndex(), pointGroup: group });
        this.props.requestPointDicts();
        this.props.requestQueryItemTypes();
        let id = point.id;
        this.props.requestPointAllInfo(id, true);
    }

    queryItems = (point: Point) => {
        this.props.requestQueryItemTypes();
        this.setState({ showQueryItemsFormPoint: point, modalZIndex: getModalZIndex() });
    }

    polling = (point: Point) => {
        this.setState({ pollingAbonents: [point], pollingGroupName: null, modalZIndex: getModalZIndex() });
    }

    pollingGroup = (parent: Group | null) => {
        if (parent != null && this.state.userGroups) {
            this.props.setIsLoading(true, 500);
            getAllSelectedPoints(this.state.userGroups, [parent.id], [], this.props.requestGroupPoints)
                .then(allPoints => {
                    this.props.setIsLoading(false);
                    this.setState({ pollingAbonents: allPoints, pollingGroupName: parent.name, modalZIndex: getModalZIndex() });
                });
        }
    }

    public render() {
        const isAdmin: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) >= 0;
        let cancelDropMessage: string | undefined;
        if (this.props.userProfile.profile.Reports?.AbonentsAndGroupsSortBy === "SortByNumber") {
            cancelDropMessage = 'У Вас выбрана опция сортировки "По алфавиту" в разделе "Настройки" вкладка "Отображение на сайте". Смените данную настройку на "В заданном порядке" для использования опции перемещения объектов в дереве.';
        }

        const rootCommands: Command[] = [
            { key: 'addGroup', caption: "Выбрать группы", action: this.addGroups },
            { key: 'createGroup', caption: "Создать группу", action: this.createGroup },
            { key: 'createPoint', caption: "Создать точку учёта", action: this.createPoint }
        ];
        const groupCommands: Command[] = [
            { key: 'editGroup', caption: "Изменить группу", action: this.editGroup },
            { key: 'createGroup', caption: "Создать группу", action: this.createGroup },
            { key: 'addGroup', caption: "Добавить группы", action: this.addGroups },
            { key: 'removeGroup', caption: "Убрать группу из группы", action: this.removeGroup },
            { key: 'deleteGroup', caption: "Удалить группу из системы", action: this.deleteGroup },
            { key: 'addPoint', caption: "Добавить точки учёта", action: this.addPoints },
            { key: 'createPoint', caption: "Создать точку учёта", action: this.createPoint },
            { key: 'pollingGroup', caption: "Мониторинг опроса", action: this.pollingGroup },
        ];
        const pointCommands: Command[] = [
            { key: 'editPoint', caption: "Изменить точку учёта", action: this.editPoint },
            { key: 'copyPoint', caption: "Скопировать точку учёта", action: this.copyPoint },
            { key: 'deletePoint', caption: "Удалить точку учёта", action: this.deletePoint },
            { key: 'removePoint', caption: "Убрать точку учёта из группы", action: this.removePoint },
            { key: 'queryItems', caption: "Задания", action: this.queryItems },
            { key: 'polling', caption: "Мониторинг опроса", action: this.polling },
        ];

        const commands: any = {};
        commands[NodeTypesEnum.Root] = rootCommands;
        commands[NodeTypesEnum.Group] = groupCommands;
        commands[NodeTypesEnum.Point] = pointCommands;

        let selectedPoint: Point | undefined = undefined;
        if (this.state.currentPointId) {
            selectedPoint = this.state.allPoints[this.state.currentPointId];
        }
        const clientHeight = this.props.page.windowHeight - 230;
        const pointsControlTreeProps = {
            height: clientHeight - 46,
            groups: this.state.userGroups ?? [],
            groupPoints: this.state.groupPoints,
            selectedPoint: selectedPoint,
            getPoints: this.requestGroupPoints,
            onSelect: this.onSelect,
            commands: isAdmin ? commands : {},
            cancelDropMessage: cancelDropMessage,
            draggable: isAdmin,
            changePointPosition: this.changePointPosition,
            changeGroupPosition: this.changeGroupPosition,
            movePoint: this.movePoint,
            moveGroup: this.moveGroup,
            needReloadGroupIds: [],
            setIsLoading: this.props.setIsLoading,
            adminMode: isAdmin
        };

        const groupFormProps: GroupFormProps = {
            open: this.state.groupFormIsOpen,
            values: this.state.groupValues,
            isNewGroup: this.state.isNewGroup,
            onSave: (values: GroupValues, isNewGroup: boolean) => {
                if (isNewGroup) {
                    this.createGroupAccept(this.state.group, values.title, values.description);
                }
                else if (this.state.group) {
                    this.changeGroupAccept(this.state.group, values.title, values.description);
                }
                this.setState({ groupFormIsOpen: false });
            },
            onCancel: () => {
                this.setState({ groupFormIsOpen: false });
            },
        }
        //Флаг запрета отображения списка. Для администратора добавить себе точки можно только через список.
        const onlyTree = !isAdmin &&
            (this.state.selectObjectsModal.onlyGroupsSelect ?
                Object.keys(this.props.userProfile.groupPoints).length < 10 :
                Object.keys(this.props.userProfile.allPoints).length < 10);

        return (
            <>
                <Row gutter={16}>
                    <Col span={isAdmin ? 16 : 0}>
                        {isAdmin &&
                            <div style={{ height: clientHeight, padding: 2, backgroundColor: '#cfcfcf' }}>
                                <UsersTable
                                    windowHeight={this.props.page.windowHeight}
                                    parentHeight={clientHeight - 78}
                                    parentWidth={this.props.page.windowWidth - (this.props.isMenuCollapsed ? 680 : 800)}
                                    eventTypes={this.props.settings.eventTypes ?? []}
                                    messageSendTypes={this.props.settings.messageSendTypes ?? {}}
                                    fileNameTypes={this.props.settings.fileNameTypes ?? {}}
                                    periodTypes={this.props.settings.periodTypes ?? {}}
                                    reportingPeriodTypes={this.props.settings.reportingPeriodTypes ?? {}}
                                    reportsTypes={this.props.settings.reportsTypes ?? {}}
                                    schedulesTypes={this.props.settings.schedulesTypes ?? {}}
                                    userProfile={this.props.userProfile}
                                    allPoints={this.state.allPoints}
                                    requestAllGroupPoints={this.props.requestGroupPoints}
                                    selectedUser={this.props.selectedUser}
                                    userGroups={this.state.userGroups}
                                    groupPoints={this.state.groupPoints}
                                    requestGroupPoints={this.requestGroupPoints}
                                    requestPoints={this.props.requestPoints}
                                    onSelectUser={this.onSelectUser}
                                    onChangeUser={this.loadUserProfile}
                                    setIsLoading={this.props.setIsLoading}
                                    closeSession={this.props.closeSession}
                                />
                            </div>}
                    </Col>
                    <Col span={isAdmin ? 8 : 24}>
                        <div style={{ height: clientHeight, padding: 2, backgroundColor: '#cfcfcf' }}>
                            <Typography.Title level={4} style={{ marginLeft: 20, marginTop: 4 }}>{this.props.selectedUser?.user} </Typography.Title>
                            {
                                this.state.userGroups !== undefined &&
                                <PointsControlTree {...pointsControlTreeProps} />
                            }
                        </div>
                    </Col>
                </Row>
                {
                    this.props.adminStore == undefined ? null :
                        <PointEditForm
                            visible={this.state.showPointEditForm}
                            onHide={() => { this.setState({ showPointEditForm: false }); this.props.setPointAllInfo(undefined) }}
                            onWrite={this.onPointWrite}
                            changeQueryItem={this.props.setQueryItem}

                            isConnParsLoading={this.props.adminStore.isConnParsLoading}
                            isPointAllInfoLoading={this.props.adminStore.isPointAllInfoLoading}
                            isQueryItemTypesLoading={this.props.adminStore.isQueryItemTypesLoading}
                            pointAllInfo={this.props.adminStore.pointAllInfo}
                            pointDicts={this.props.adminStore.pointDicts}
                            queryItemTypes={this.props.adminStore.queryItemTypes}
                            queryItem={this.props.adminStore.queryItem}

                            allResources={this.props.userProfile.resources}
                            changePoint={this.props.setPointAllInfo}
                            requestConnPars={this.props.requestConnPars}
                            setIsLoading={this.props.setIsLoading}
                            baseMapLayer={this.props.profile.Map?.BaseLayer}
                            changeBaseMapLayer={(val: string) => this.props.changeProfileValue(Const.PROFILE_MAP, 'BaseLayer', val)}
                            readSettings={this.readPointSettings}
                            requestPointAllInfo={(success: () => void) => this.props.requestPointAllInfo(this.props.adminStore.pointAllInfo?.pointInfo.id ?? null, false, success)}

                            changeProfile={this.props.changeProfile}
                            setActiveQueries={this.props.setActiveQueries}
                            showModal={this.props.showModal}
                            showPointInfo={this.props.showPointInfo}
                            userProfile={this.props.userProfile}
                            zIndex={this.state.modalZIndex}
                            windowHeight={this.props.windowHeight}
                            activeTasks={this.props.tasks.activeTasks}
                            setActiveLogTask={this.props.setActiveLogTask}
                            getActiveTasks={this.props.getActiveTasks}
                            readData={this.props.readData}
                            showPointLog={
                                (pointId: string) => {
                                    if (this.state.currentPointId)
                                        this.props.addPointLog(this.state.allPoints[this.state.currentPointId]);
                                }
                            }
                        />
                }
                {
                    this.state.groupFormIsOpen ? <GroupForm {...groupFormProps} /> : null
                }
                {
                    <SelectForm
                        title={this.state.selectObjectsModal.onlyGroupsSelect ? "Выбор групп" : "Выбор точек учёта"}
                        visible={this.state.selectObjectsModal.visible}
                        zIndex={this.state.selectObjectsModal.zIndex}
                        clientHeight={clientHeight}
                        groups={this.props.userProfile.userGroups}
                        groupPoints={this.props.userProfile.groupPoints}
                        selectedGroupIds={this.state.selectObjectsModal.selectedGroupsIds}
                        selectedPointIds={this.state.selectObjectsModal.selectedPointsIds}
                        getPoints={this.props.requestGroupPoints}
                        getMultipleGroupPoints={this.props.requestMultipleGroupPoints}
                        getPointsParentGroups={null}
                        onChange={(groups: string[], points: string[]) => this.setState(
                            { selectObjectsModal: { ...this.state.selectObjectsModal, selectedGroupsIds: groups, selectedPointsIds: points } }
                        )}
                        onChangePoints={this.onPointsSelect}
                        onChangeGroups={this.onGroupsSelect}
                        setIsLoading={this.props.setIsLoading}
                        onlyGroupsSelect={this.state.selectObjectsModal.onlyGroupsSelect}
                        onClose={() => this.setState(
                            { selectObjectsModal: { ...this.state.selectObjectsModal, visible: false } }
                        )}
                        resources={this.props.userProfile.resources}
                        onlyTree={onlyTree}
                    />
                }
                {
                    <QueryItemsForm
                        onHide={() => { this.setState({ showQueryItemsFormPoint: null }) }}
                        point={this.state.showQueryItemsFormPoint}
                        setIsLoading={this.props.setIsLoading}
                        readData={this.props.readData}
                        setActiveLogTask={this.props.setActiveLogTask}
                        getActiveTasks={this.props.getActiveTasks}
                        queryItemTypes={this.props.adminStore.queryItemTypes}
                        zIndex={this.state.modalZIndex}
                        activeTasks={this.props.tasks.activeTasks}
                        onQueryItemsStarted={(id: string, taskIds: number[]) => {
                            this.props.setActiveLogTask(id, taskIds.length > 0 ? taskIds[0] : 0)
                        }}
                    />
                }
                {
                    <PollingForm
                        points={this.state?.pollingAbonents ?? null}
                        zIndex={this.state.modalZIndex}
                        onHide={() => this.setState({ pollingAbonents: null })}
                        setIsLoading={this.props.setIsLoading}
                        groupName={this.state?.pollingGroupName ?? null}
                        changeProfile={this.props.changeProfile}
                        setActiveQueries={this.props.setActiveQueries}
                        showModal={this.props.showModal}
                        showPointInfo={this.props.showPointInfo}
                        userProfile={this.props.userProfile}
                        windowWidth={this.props.windowWidth}
                        setActiveLogTask={this.props.setActiveLogTask}
                    />
                }
            </>
        );
    }
}

function mapStateToProps(state: any, ownProps: any) {

}

export default connect(
    (state: ApplicationState, props: any) => {
        return {
            adminStore: state.adminStore,
            page: state.page,
            tasks: state.tasks,
            settings: state.settingsStore,
            profile: state.userProfile?.profile,
            userProfile: state.userProfile,
            pointId: state.page.pointId,
            isMenuCollapsed: state.siderMenu?.collapsed,
            windowWidth: state.page.windowWidth,
            windowHeight: state.page.windowHeight,
            selectedUser: props.selectedUser,
            onSelectUser: props.onSelectUser
        }
    },
    actionCreators,

)(UsersManager as any);
