import * as React from 'react';
import { DatePicker, Input, Select, Switch, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import Groups from './Groups';
import { ApplicationState } from '../store';
import * as MnemoschemaStore from '../store/MnemoschemaStore';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as UserProfileStore from '../store/UserProfile';

import { netiExecute, message, canReadData } from '../utils/Common';

import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { Point, PeriodsEnum, BaseProfile, USER_RIGHTS } from "../decl"
import * as Const from '../utils/Const';

const { Option } = Select;

import {
    SyncOutlined,
    DownloadOutlined,
    SettingOutlined,
    ScheduleTwoTone,
    ScheduleOutlined
} from '@ant-design/icons';

import { Periods } from './PageHeader';
import MnenoschemaSettingsForm from './MnemoschemaSettingsForm';
import CollapsedButton from './Helper/CollapsedButton';
import ReactDOM from 'react-dom';
import { MiddleContentMessageBlock } from './HeaderForm';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...MnemoschemaStore.actionCreators, ...TasksStore.actionCreators  };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, mnemoschemas: MnemoschemaStore.IMnemoschemasState,
        pointId: string, windowWidth: number, isMenuCollapsed: boolean, } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    showSettings: boolean,
    modalZIndex: number,
    waitData: boolean,

    timerId: any;
    timerPeriodSec: number | undefined;
}

class MnemoschemaHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            showSettings: false,
            modalZIndex: 0,
            timerId: undefined,
            timerPeriodSec: undefined,
            waitData: false,
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    componentDidMount() {
        this.props.requestMnemoschemas();
    }

    componentDidUpdate(prevProps: HeaderProps, prevState: HeaderState) {
        if (this.props.mnemoschemas == undefined) {
            return;
        }
        
        if (this.props.mnemoschemas.mnemoschemas !== prevProps.mnemoschemas.mnemoschemas && this.props.mnemoschemas.mnemoschemas != undefined) {
            if (this.props.mnemoschemas.currentMnenoschemaId === undefined) {
                //Если список мнемосхем обновлися и не выбрана не одна мнемосхема, пробуем назначить одну из списка
                this.props.setCurrentMnemoschemaId(this.props.mnemoschemas?.mnemoschemas?.find(item => item.isGeneric === this.props.mnemoschemas.isGeneric)?.id);
            } else {
                //Если список мнемосхем обновился смотрим подходил ли выбранная мнемосхема к новому списку,
                //если нет то обнуляем выбор мнемосхемы
                //если да то запрашиваем картинку и данные
                const mnemo = this.props.mnemoschemas?.mnemoschemas?.find(item => item.id === this.props.mnemoschemas.currentMnenoschemaId);
                if ( mnemo === undefined || mnemo.isGeneric !== this.props.mnemoschemas.isGeneric) {
                    this.props.setCurrentMnemoschemaId(undefined)
                } else {
                    if (this.props.mnemoschemas.currentMnenoschemaId != undefined) {
                        //По сути этот блок срабатывает при нажатии кнопки "обновить"
                        this.props.requestMnemoschemaData(this.props.mnemoschemas.currentMnenoschemaId);
                        this.requestMnemoschemaValues();
                    }
                }
            }
        }

        //читаем данные и картинку если изменилась мнемосхема
        if ((prevProps.mnemoschemas.currentMnenoschemaId !== this.props.mnemoschemas.currentMnenoschemaId && this.props.mnemoschemas.currentMnenoschemaId != undefined)) {
            this.props.requestMnemoschemaData(this.props.mnemoschemas.currentMnenoschemaId);
            this.requestMnemoschemaValues();
        }

        //Читаем данные если изменилась ТУ или период
        if ((prevProps.pointId !== this.props.pointId && this.props.pointId !== '')
             || (prevProps.userProfile.profile.Common?.ArchivePeriod !== this.props.userProfile.profile.Common?.ArchivePeriod))
        {
            this.requestMnemoschemaValues();
        }

        //Заполняем перечень абонентов
        if ((prevProps.mnemoschemas.fields !== this.props.mnemoschemas.fields && this.props.mnemoschemas.fields != undefined)
                || (prevProps.pointId !== this.props.pointId && this.props.pointId !== ''))
        {
            let abonents: {[id: string]: string} = {};
            if (this.props.mnemoschemas.mnemoschemas != undefined) {
                if (this.props.mnemoschemas.mnemoschemas?.find(item => item.id === this.props.mnemoschemas.currentMnenoschemaId)?.isGeneric === true) {
                    if (this.props.pointId != undefined && this.props.pointId !== '') {
                        abonents[this.props.pointId] = this.props.userProfile.allPoints[this.props.pointId].number;
                    }
                }
                if (this.props.mnemoschemas.fields != undefined) {
                    this.props.mnemoschemas.fields.forEach(item => item.abonentNumber != '' && abonents[item.abonentId] == undefined ? abonents[item.abonentId] = item.abonentNumber : null )
                }
            }

            this.props.setMnenoschemaAbonents(abonents);
        }

        //---
        let timerPeriodSec: number | undefined = undefined;
        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.current)
            timerPeriodSec = this.props.userProfile.profile.Mnemoschema?.CurrentRefreshPeriod;
        else if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.hour)
            timerPeriodSec = this.props.userProfile.profile.Mnemoschema?.HourRefreshPeriod;
        else if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.day)
            timerPeriodSec = this.props.userProfile.profile.Mnemoschema?.DayRefreshPeriod;

        if (this.props.userProfile.profile.Mnemoschema?.AutoRefresh === true && this.state.timerId === undefined && timerPeriodSec != undefined) {
            this.startTimer(timerPeriodSec);
        }

        if (this.props.userProfile.profile.Mnemoschema?.AutoRefresh === false && this.state.timerId !== undefined) {
            this.stopTimer();
        }

        if (this.state.timerPeriodSec !== timerPeriodSec && timerPeriodSec != undefined) {
            this.restartTimer(timerPeriodSec);
        }
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string) => {
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointInfo(pointId, pointNumber, source, parent);
    }
    showPointsData = (pointIds: string[], period: number, normalDataDepth: number) => {
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointsData(pointIds as any, period, normalDataDepth, source, parent);
    }
    onShowAbonents = () => {
        if (this.props.mnemoschemas.abonents != undefined) {

            if (Object.keys(this.props.mnemoschemas.abonents ?? {}).length === 1){
                const k = Object.keys(this.props.mnemoschemas.abonents ?? {})[0];
                this.showPointInfo(k, this.props.mnemoschemas.abonents[k]);
            } else {
                let ndd = 200;
                if (this.props.userProfile?.profile?.Common?.ArchivePeriod === PeriodsEnum.current) {
                    ndd = this.props.userProfile.profile.Mnemoschema?.CurrentNormalDataDepth ?? 200;
                } else if (this.props.userProfile?.profile?.Common?.ArchivePeriod === PeriodsEnum.day) {
                    ndd = this.props.userProfile.profile.Mnemoschema?.DayNormalDataDepth ?? 200;
                } else if (this.props.userProfile?.profile?.Common?.ArchivePeriod === PeriodsEnum.hour) {
                    ndd = this.props.userProfile.profile.Mnemoschema?.HourNormalDataDepth ?? 200;
                }
                this.showPointsData(Object.keys(this.props.mnemoschemas.abonents ?? {}), this.props.userProfile?.profile?.Common?.ArchivePeriod ?? PeriodsEnum.current, ndd);
            }
        }
    }

    requestDeviceData = () => {
        const period = this?.props?.userProfile?.profile?.Common?.ArchivePeriod;

        if (period !== undefined && this.props.mnemoschemas.abonents != undefined) {
            message.success('Запрос на получение данных из приборов отправлен.');
            this.setState({ waitData: true });

            this.props.readData(
                period,
                Object.keys(this.props.mnemoschemas.abonents ?? {}),
                100,
                false,
                (code: number, ids: any) => {
                    if (code === 0) {
                    //    message.success('Запрос на получение данных из приборов отправлен.');
                    //    this.setState({ waitData: true });
                    }
                    if (code === 2) {
                        message.success('Данные из приборов получены.');
                        this.setState({ waitData: false });
                        this.props.requestMnemoschemas();                    }
                    if (code === 4) {
                        message.warning('Не все данные из приборов удалось получить.');
                        this.setState({ waitData: false });
                    }
                },
                (error: string) => {
                    message.error(error);
                    console.log(error);
                    this.setState({ waitData: false });
                });
        }
    }

    requestMnemoschemaValues = () => {
        if (this.props.mnemoschemas.currentMnenoschemaId!= undefined) {
            if (this.props.mnemoschemas.isGeneric) {
                this.props.requestMnemoschemaValues(this.props.mnemoschemas.currentMnenoschemaId, this.props.userProfile?.profile?.Common?.ArchivePeriod ?? PeriodsEnum.current, this.getDepth(), this.props.pointId);
            } else {
                this.props.requestMnemoschemaValues(this.props.mnemoschemas.currentMnenoschemaId, this.props.userProfile?.profile?.Common?.ArchivePeriod ?? PeriodsEnum.current, this.getDepth(), undefined);
            }
        }
    }

    getDepth = () => {
        let res: number = 240;
        if (this.props.userProfile.profile.Common && this.props.userProfile.profile.Mnemoschema) {
            if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.current)
                res = this.props.userProfile.profile.Mnemoschema.CurrentDataDepth;
            else if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.day)
                res = this.props.userProfile.profile.Mnemoschema.DayDataDepth;
            else if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.hour)
                res = this.props.userProfile.profile.Mnemoschema.HourDataDepth;
        }
        return res;
    }

    onRefresh = () => {
        this.props.requestMnemoschemas();
    }

    onSelectPoint = (pointId: string) => {
        const point: Point | undefined = this.props.userProfile.allPoints[pointId];
        if (point == undefined) {
            const error = 'Не найдена ТУ в кеше.';
            message.error(error);
            console.log(error);
        } else {
            this.props.setPoint(point.id, point.number);
            if(this.state.waitData) this.setState({ waitData: false });
        }
    };
    onSelectMnemoschema = (id: number | undefined) => {
        this.props.setCurrentMnemoschemaId(id);
        if(this.state.waitData) this.setState({ waitData: false });
    };

    onPeriodsChange = (value: PeriodsEnum) => {
        this.props.changeProfileValue(Const.PROFILE_COMMON, 'ArchivePeriod', value);
    };

    onChecked = (val: boolean) => {
        this.props.setIsGeneric(val);
        this.props.setCurrentMnemoschemaId(this.props.mnemoschemas?.mnemoschemas?.find(item => item.isGeneric === val)?.id)
    }

    restartTimer = (newVal: number) => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            let id = setInterval(this.timerTick, newVal * 1000);
            this.setState({timerId: id, timerPeriodSec: newVal});
        }
    }

    startTimer = (val: number) => {
        if (this.state.timerId === undefined) {
            let id = setInterval(this.timerTick, val * 1000);
            this.setState({timerId: id});
        }
    }

    stopTimer = () => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            this.setState({timerId: undefined})
        }
    }

    timerTick = () => {
        if (this.props.mnemoschemas.isLoadingData !== true) {
            this.requestMnemoschemaValues();
        }
    }

    render() {
        const GroupsProps2 = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: [],
            selectedPointIds: [this.props.pointId],
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onSelectPoint: this.onSelectPoint,
            setIsLoading: this.props.setIsLoading,
            treeCheckable: false,
        };
        const period: PeriodsEnum = ((this.props?.userProfile?.profile?.Common?.ArchivePeriod) ?? 0) as PeriodsEnum;
        const PeriodsProps = {
            value: period,
            onChange: this.onPeriodsChange
        };
        const buttonAsIcon = this.props.windowWidth < 1850;
        const freeze = this.props.mnemoschemas.isLoadingData || this.props.mnemoschemas.isLoading;
        const disableGeneric = this.props.mnemoschemas?.mnemoschemas?.find(item => item.isGeneric === true) === undefined ?? true;
        const disableCustom = this.props.mnemoschemas?.mnemoschemas?.find(item => item.isGeneric === false) === undefined ?? true;
        const disableSwitch = this.props.mnemoschemas.isGeneric ? disableCustom : disableGeneric;

        const mnemoschemas = this.props.mnemoschemas.mnemoschemas?.filter(item => item.isGeneric === this.props.mnemoschemas.isGeneric).map(item => { return (
            <Option key={item.id} value={item.id}>{item.caption}</Option>
        )});
        return (
            <div className={styles.flexcontainer}>
                <div key='k1' className={styles.comboblock}>
                    <Switch disabled={freeze|| disableSwitch} key='k1' checkedChildren="Типовые" unCheckedChildren="Индивидуальные" style={{minWidth: '145px'}} checked={this.props.mnemoschemas.isGeneric}
                        onChange={this.onChecked} />

                    <div  key='k2' className={styles.flexitem}>
                        <Tooltip placement="right" title={'Выбор мнемосхемы'}>
                            <Select disabled={freeze} value={this.props.mnemoschemas.currentMnenoschemaId} onChange={this.onSelectMnemoschema} style={{ width: '170px' }}>
                                {mnemoschemas}
                            </Select>
                        </Tooltip>
                    </div>
                    {
                        this.props.mnemoschemas.isGeneric ?
                            <div className={styles.flexitem}>
                                { <Groups disabled={freeze} {...GroupsProps2} />}
                            </div>
                        : null
                    }
                    <div key='k3' className={styles.flexitem}>
                        <Periods disabled={freeze} {...PeriodsProps} />
                    </div>
                    <div key='k4' className ={styles.buttonsblock}>
                        <CollapsedButton key='k1' disabled={freeze} isCollapsed={buttonAsIcon} onClick={this.onRefresh} icon={<SyncOutlined twoToneColor={Const.COLORS.SvgIconColor} />} type="primary">Обновить</CollapsedButton>
                        <CollapsedButton key='k2' disabled={freeze} isCollapsed={buttonAsIcon} onClick={this.onShowAbonents} icon={<ScheduleOutlined twoToneColor={Const.COLORS.SvgIconColor} />} type="primary">Инфо</CollapsedButton>
                        <CollapsedButton key='k3' disabled={freeze || this.state.waitData || !canReadData(this.props.userProfile?.rights, period)} isCollapsed={buttonAsIcon} onClick={this.requestDeviceData} icon={<DownloadOutlined twoToneColor={Const.COLORS.SvgIconColor} />} type="primary">Сбор данных</CollapsedButton>
                        <CollapsedButton key='k4' disabled={freeze} isCollapsed={buttonAsIcon} onClick={() => this.setState({ showSettings: true })} icon={<SettingOutlined twoToneColor={Const.COLORS.SvgIconColor} />} type="primary">Настройки</CollapsedButton>
                    </div>
                </div>
                <div key='k2' className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={1460} onlyIcon={true} hideEmail={false} isSplit={false}/>
                </div>
                <div key='k3' className={styles.flexuseritem}>
                    <Login />
                </div>

                <MnenoschemaSettingsForm
                    key='k4'
                    onHide={() => this.setState({showSettings: false})}
                    visible={this.state.showSettings}
                    zIndex={this.state.modalZIndex}
                />
            </div>
        )
    }
}



export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            mnemoschemas: state.mnemoschemas,
            pointId: state.page.pointId,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed,
        }
    },
    actionCreators
)(MnemoschemaHeaderForm as any);
