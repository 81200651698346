import { useEffect } from 'react';
import {Modal, Form, Input, Radio, message} from 'antd';


export interface GroupValues {
  title: string;
  description: string;
}

export interface GroupFormProps {
  open: boolean;
  isNewGroup: boolean;
  values?: GroupValues  
  onSave: (values: GroupValues, isNew: boolean) => void;
  onCancel: () => void;
}

export const GroupForm: React.FC<GroupFormProps> = ({
  open,
  values,
  isNewGroup,
  onSave,
  onCancel
}) => {


  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  });
  
  return (
    <Modal
      open = {open}
      title="Группа"
      okText="Сохранить"
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            if (values.title.trim() !== '') {
              onSave(values, isNewGroup);
            } else {
              message.warning('Необходимо задать название группы');
            }
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ title: values ? values.title : '', description: values ? values.description : '',}}
      >
        <Form.Item
          name="title"
          label="Название"
          rules={[{ required: true, message: 'Нужно дать название группе!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Описание">
          <Input type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  );
};