import { Action, Reducer } from 'redux';
import { CloseSessionAction } from "./Page";
import { SBilContactDTO, SBillingCompanyInfoDTO } from '../components/Billing/BillingDecls';
import { AppThunkAction } from '.';
import { sendRequestToBackend } from '../utils/AuthUtils';
import { message } from 'antd';
import * as PageStore from './Page';

export interface IBillingState {
    showCompaniesForm: boolean,
    companies: SBillingCompanyInfoDTO[] | undefined,
    contacts: SBilContactDTO[] | undefined,
    showTariffsForm: boolean,
    showStatisticsForm: boolean,
    showPromisedPaymentForm: boolean,
    showParsePaymentsForm: boolean,
    showActiveContractsForm: boolean,
    filterContracts: string,
}

interface SetState { type: 'SET_BILLING_STATE'; value: Partial<IBillingState> };

type KnownAction = SetState | CloseSessionAction;

export const actionCreators = {
    setShowCompaniesForm: (flag: boolean) => ({ type: 'SET_BILLING_STATE', value: {showCompaniesForm: flag} } as SetState),
    setShowTariffsForm: (flag: boolean) => ({ type: 'SET_BILLING_STATE', value: {showTariffsForm: flag} } as SetState),
    setShowPromisedPaymentForm: (flag: boolean) => ({ type: 'SET_BILLING_STATE', value: {showPromisedPaymentForm: flag} } as SetState),
    setShowParsePaymentsForm: (flag: boolean) => ({ type: 'SET_BILLING_STATE', value: {showParsePaymentsForm: flag} } as SetState),
    setShowStatisticsForm: (flag: boolean) => ({ type: 'SET_BILLING_STATE', value: {showStatisticsForm: flag} } as SetState),
    setShowActiveContractsForm: (flag: boolean) => ({ type: 'SET_BILLING_STATE', value: {showActiveContractsForm: flag} } as SetState),
    setContacts: (arr: SBilContactDTO[] | undefined) => ({ type: 'SET_BILLING_STATE', value: {contacts: arr} } as SetState),
    setCompanies: (arr: SBillingCompanyInfoDTO[] | undefined) => ({ type: 'SET_BILLING_STATE', value: {companies: arr} } as SetState),
    setFilterContracts: (val: string) => ({ type: 'SET_BILLING_STATE', value: {filterContracts: val} } as SetState),

    requestCompanies: (success: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({ type: 'SET_BILLING_STATE', value: {companies: undefined}});
        
        if (appState) {
            sendRequestToBackend(
                null,
                'billing/companies',
                (response: any) => {
                    if (response != null) {
                        dispatch({ type: 'SET_BILLING_STATE', value: {companies: response}});
                        success();
                    } else {
                        message.error('Запрос завершился неудачей');
                    }
                },
                PageStore.actionCreators.setIsLoading,
                (error: any) => {
                    console.log(error);
                    message.error(error);
                }
            );
        }
    },

    requestContacts: (companyId: number, success: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({ type: 'SET_BILLING_STATE', value: {contacts: undefined}});
        
        if (appState) {
            sendRequestToBackend(
                companyId,
                'billing/contacts',
                (response: any) => {
                    if (response != null) {
                        dispatch({ type: 'SET_BILLING_STATE', value: {contacts: response}});
                        success();
                    } else {
                        message.error('Запрос завершился неудачей');
                    }
                },
                PageStore.actionCreators.setIsLoading,
                (error: any) => {
                    console.log(error);
                    message.error(error);
                }
            );
        }
    },
    
};

export const reducer: Reducer<IBillingState> = (state: IBillingState | undefined, incomingAction: Action): IBillingState => {
    if (state === undefined) {
        return {companies: undefined, showCompaniesForm: false, contacts: undefined, showTariffsForm: false, showStatisticsForm: false, showPromisedPaymentForm: false, 
            showParsePaymentsForm: false, showActiveContractsForm: false, filterContracts: ""};
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_BILLING_STATE":
            return {
                ...state,
                ...action.value
            };
        default:
            return state;
    }
};
